define("discourse/plugins/discourse-category-experts/discourse/connectors/topic-list-after-title/category-experts-indicator", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get approvedPills() {
      const {
        topic
      } = this.args.outletArgs;
      return topic.expert_post_group_names?.map(groupName => ({
        href: this.siteSettings.category_experts_topic_list_link_to_posts ? `${topic.url}/${topic.first_expert_post_id}` : "/search?q=with:category_expert_response",
        groupName
      }));
    }
    get needsApprovalHref() {
      const {
        topic
      } = this.args.outletArgs;
      if (this.currentUser?.staff && topic.needs_category_expert_post_approval) {
        return this.siteSettings.category_experts_topic_list_link_to_posts ? `${topic.url}/${topic.needs_category_expert_post_approval}` : "/search?q=with:unapproved_ce_posts";
      }
    }
    get questionPillHref() {
      const {
        topic
      } = this.args.outletArgs;
      if (topic.is_category_expert_question && this.currentUser && (this.currentUser.staff || topic.creator && topic.creator.id === this.currentUser.id || this.currentUser.expert_for_category_ids.includes(topic.category_id))) {
        return this.siteSettings.category_experts_topic_list_link_to_posts ? topic.url : "/search?q=is:category_expert_question";
      }
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#each this.approvedPills as |pill|}}
          <span class="topic-list-category-expert-tags">
            <a href={{pill.href}} class={{pill.groupName}}>
              {{i18n
                "category_experts.topic_list.response_by_group"
                groupName=pill.groupName
              }}
            </a>
          </span>
        {{/each}}
    
        {{#if this.needsApprovalHref}}
          <a
            href={{this.needsApprovalHref}}
            class="topic-list-category-expert-needs-approval"
          >
            {{i18n "category_experts.topic_list.needs_approval"}}
          </a>
        {{/if}}
    
        {{#if this.questionPillHref}}
          <a
            href={{this.questionPillHref}}
            class="topic-list-category-expert-question"
          >
            {{i18n "category_experts.topic_list.question"}}
          </a>
        {{/if}}
      
    */
    {
      "id": "KFu0vMu7",
      "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"approvedPills\"]]],null]],null],null,[[[1,\"      \"],[10,1],[14,0,\"topic-list-category-expert-tags\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"href\"]]],[15,0,[30,1,[\"groupName\"]]],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"category_experts.topic_list.response_by_group\"],[[\"groupName\"],[[30,1,[\"groupName\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"needsApprovalHref\"]],[[[1,\"      \"],[10,3],[15,6,[30,0,[\"needsApprovalHref\"]]],[14,0,\"topic-list-category-expert-needs-approval\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"category_experts.topic_list.needs_approval\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"questionPillHref\"]],[[[1,\"      \"],[10,3],[15,6,[30,0,[\"questionPillHref\"]]],[14,0,\"topic-list-category-expert-question\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"category_experts.topic_list.question\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"pill\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-experts/discourse/connectors/topic-list-after-title/category-experts-indicator.js",
      "scope": () => [_discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = _default;
});